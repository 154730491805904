<template>
	<div>
		<Header :childIndex="0"></Header>
		<div class="main_box">
			<div class="main_boxc">
				<div class="title_box">
					<router-link :to="{path:'/'}">
						<p>首页 ></p>
					</router-link>
					<span>{{$route.query.type == 4?'音频':'视频'}}列表</span>
				</div>
				<div class="list_box">
					<div class="list_box_l">
						<div class="list_class">
							<div class="list_title">
								<em></em>
								<span>{{$route.query.type == 4?'音频':'视频'}}列表</span>
							</div>
							<div class="list_class_r">
								<input type="text" v-model="searchWord" />
								<img @click="toSearch()" src="../../../images/search_ico4.png" />
							</div>
						</div>
						<div v-if="mainList.length > 0">
							<div class="list_box_con">
								<ul>
									<li v-for="(value,index) in mainList" :key="index">
										<!-- 音频 -->
										<div class="audio_box" v-if="$route.query.type == 4">
											<div class="audio_box_l" @click="playMedia(value.ID)">
												<img v-if="!value.CoverPath" src="../../../images/audio_cover.png" />
												<img v-if="value.CoverPath" :src="$ImgUrl + value.CoverPath" />
											</div>
											<div class="audio_box_r">
												<h3 @click="playMedia(value.ID)" :title="value.ResName">{{value.ResName}}</h3>
												<span>{{value.Time}}</span>
												<p :title="value.Itroduce">{{value.Itroduce | GetSubstring(30)}}</p>
											</div>
										</div>
										<!-- 视频 -->
										<div class="video_box" v-if="$route.query.type == 5">
											<div class="video_box_l" @click="playMedia(value.ID)">
												<img :src="$ImgUrl + value.CoverPath" :onerror="$parent.defaultImg1" />
											</div>
											<div class="audio_box_r video_box_r">
												<h3 @click="playMedia(value.ID)" :title="value.ResName">{{value.ResName}}</h3>
												<span>{{value.Time}}</span>
												<span>{{value.ResExpand}}</span>
												<p :title="value.Itroduce">{{value.Itroduce | GetSubstring(30)}}</p>
											</div>
										</div>
									</li>
								</ul>
								<div class="news_page">
									<page :page-index="pageIndex" :total="pageCount" :page-size="pageSize" @change="pageChange"></page>
								</div>
							</div>
						</div>
						<NoData v-if="mainList.length == 0"></NoData>
					</div>
					<div class="news_boxr list_box_r">
						<div class="news_boxr1 m_b22">
							<div class="listtitle_box">
								<i></i>
								<img src="../../../images/rmzsy_ico.png" />
							</div>
							<div class="news_about">
								<ul>
									<li v-for="(value,index) in HotElementList" :key="index">
										<div class="news_aboutl" @click="toElementDetail(value.id)">
											<img :src="$ImgUrl+value.cover_path" :onerror="$parent.defaultImg1" />
										</div>
										<div class="news_aboutr">
											<h2 @click="toElementDetail(value.id)">{{value.element_name}}</h2>
											<p>{{value.Annotation | GetSubstring(26)}}</p>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div class="news_boxr1  m_b22">
							<div class="listtitle_box">
								<i></i>
								<img src="../../../images/rmwz_ico.png" />
							</div>
							<div class="news_boxr1list">
								<ul>
									<li v-for="(value,index) in HotEntryList" :key="index">
										<a href="javascript:void(0)" @click="toEntryDetail(value.id)">
											<h3 :title="value.Title">{{value.entry_name}}</h3>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
		<LoginPop :showLogin='showLogin'></LoginPop>
		<div class="video_player" v-show="showPlayer">
			<div class="video_player_c" :style="$route.query.type == 5?'width: 1200px':'width: 400px;'">
				<div class="dltc_box1_maint">
					<h4>播放</h4>
					<button class="Pop_btn" style="top: 5px;" @click="hidePlayer">
						<img src="../../../images/ts_close.png" />
					</button>
				</div>
				<div class="index_audio" v-if="$route.query.type == 4">
					<div class="index_audioc">
						<div class="index_audioc_l">
							<img v-if="!audioInfo.CoverPath" src="../../../images/audio_cover.png"/>
							<img v-if="audioInfo.CoverPath" :src="$ImgUrl + audioInfo.CoverPath"/>
						</div>
						<div class="index_audioc_r">
							<h3 :title="audioInfo.ResName">{{audioInfo.ResName}}</h3>
							<span v-if="audioInfo.Time">时长：{{audioInfo.Time}}</span>
						</div>
					</div>
					<audio class="media" ref="player" controlsList="nodownload" :src="$ImgUrl + mediaFilePath" controls="controls"></audio>
				</div>
				<div v-if="$route.query.type == 5">
					<div class="play_layerc1" v-if="mediaFilePath && mediaFilePath.indexOf('<iframe') == -1">
						<video class="media" ref="videoPlayer" autoplay="autoplay" :src="$ImgUrl + mediaFilePath" controls="controls" controlsList="nodownload"></video>
					</div>
					<div class="play_layerc1" v-if="mediaFilePath && mediaFilePath.indexOf('<iframe') != -1" v-html="mediaFilePath"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./MediaList.js">
</script>

<style scoped="scoped">
	.audio_cover{ border: 2px solid #eaddd4; width: 100%; height: 126px; box-sizing: border-box; text-align: center; line-height: 122px;}
	.audio_cover img{ width: auto !important; height: auto !important; border: none; vertical-align: middle; max-width: 100%; max-height: 100%;}
	.video_player{ position: fixed; z-index: 1000000; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5); display: flex; justify-content: space-around; align-items: center;}
	.video_player_c{ width: 400px; min-height: 160px; background: #fff; box-sizing: border-box; overflow: hidden;}
	.video_player_c .dltc_box1_maint{ width: 100%;}
	.video_player_c audio{ margin: 50px 40px 0; width: calc( 100% - 80px ); min-height: 30px; display: block !important; outline: none;}
	.play_layerc1{ float: left; width: 100%; padding: 18px; box-sizing: border-box;}
	.play_layerc1 video{ float: left; width: 100%; height: 500px; outline: none;}
	.play_layerc1 iframe{ width: 100%; height: 500px;}
</style>
